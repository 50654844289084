import { createSlice } from '@reduxjs/toolkit'
import {FormatEntity} from '../../api/model'
// contains all the three slips supported on the system 
let all_slips = {
    slip1: {
            // contains currently selected picks
            selected_odds: {},

            // the currently typed in amount on amount input 
            amount: 20,

            // current coupon ID 
            couponID: null,

            // used to check if there is any placed bet currently. Has to close first to place a bet
            placed_bet: false,

            // the order of the current item 
            order: 0,
    },

    slip2: {
            // contains currently selected picks
            selected_odds: {},

            // the currently typed in amount on amount input 
            amount: 20,

            // current coupon ID 
            couponID: null,

            // used to check if there is any placed bet currently. Has to close first to place a bet
            placed_bet: false,

            // the order of the current item 
            order: 0,
    },

    slip3: {
            // contains currently selected picks
            selected_odds: {},

            // the currently typed in amount on amount input 
            amount: 20,

            // current coupon ID 
            couponID: null,

            // used to check if there is any placed bet currently. Has to close first to place a bet
            placed_bet: false,

            // the order of the current item 
            order: 0,
    },

}


const cacheSlip = (current_slip_key, current_slip, current_selected_odds) => {


    all_slips[current_slip_key] = current_slip
}


const findMatchInSlip = (matchID, slip) => {

    // Check if the match ID is in the slip already 
    let odd_ids = Object.keys(slip.selected_odds)

    for (let i = 0; i < odd_ids.length; i++) {

        let match = slip.selected_odds[odd_ids[i]].match

        if (matchID == match.id)
            return odd_ids[i]

    }

    return -1
}

const isSlipFull = (max_slip_size, slip) => {
    return Object.keys(slip).length >= max_slip_size
}

const removeSlip = (oddID, slip) => {
    /*
    set_odd_state serves to make sure which action 
    is performed after the odd element is deleted. 
    if it is true, it means the new updated odds values should 
    be reflected on the react state. If not, just return the updated
    values.
    */


    if (oddID != -1)
        delete slip[oddID]

    return slip



}

const generateSelectedOddData = (payload, order) => {
    const selected_pick = payload.selected_pick
    const bet_group = {
        name: FormatEntity.formatBetTypeame(payload.prematch.bet_groups[selected_pick.bet_group].name, selected_pick.item.specifier,selected_pick.match),
    }

    const bet_type = {
        name: FormatEntity.formatBetTypeame(payload.prematch.bet_types[selected_pick.bet_type].name, selected_pick.match, selected_pick.item.specifier)
    }


    return {
        item: selected_pick.item,
        bet_group: bet_group,
        bet_type: bet_type,
        order: order,
        id: selected_pick.id,
        value: selected_pick.odd,
        match: selected_pick.match
    }
}

const copySelectedOdd = ( selected_odds) => {
    return {
        ...selected_odds
    }
}


export const slipSlice = createSlice({
    name: 'slip',
    initialState: {
        selected_slip: {
            // contains currently selected picks
            selected_odds: {},

            // the currently typed in amount on amount input 
            amount: 20,

            // current coupon ID 
            couponID: null,

            // used to check if there is any placed bet currently. Has to close first to place a bet
            placed_bet: false,

            // the order of the current item 
            order: 0,
        },

        current_slip_key: 'slip1'
    },
    reducers: {

        /*
            * Expected payload 
            {
                'max_slip_size' : 12,
                'selected_pick' : {
                    match : {},
                    bet_type : 12,
                    bet_group : 12,
                    item : {},
                    odd : 12
                }
            }
        */
        addPickToSlip: (state, action) => {

            const payload = action.payload
            const selected_pick = payload.selected_pick

            if (!selected_pick.odd)
                return state

            if (state.placed_bet)
                return state

            let current_selected_odds = { ...state.selected_slip.selected_odds }
            const matchOddID = findMatchInSlip(selected_pick.match.id, state.selected_slip)

            if (matchOddID != -1)
                current_selected_odds = removeSlip(matchOddID, current_selected_odds)

            if (isSlipFull(payload.max_slip_size, current_selected_odds))
                return state

            if( matchOddID != selected_pick.id )
                current_selected_odds[selected_pick.id] = generateSelectedOddData(payload, state.selected_slip.order)

            state.selected_slip.selected_odds = current_selected_odds
            state.selected_slip.order += 1


        },

        /*
        {
            'matchID' : 123
        }
        */

        removePickFromSlip : ( state, action) => {
            console.log("papyloadd", action)
            const matchOddID = findMatchInSlip(action.payload.matchID, state.selected_slip)
            let current_selected_odds = { ...state.selected_slip.selected_odds }

            if (matchOddID != -1)
                current_selected_odds = removeSlip(matchOddID, current_selected_odds)
            
            state.selected_slip.selected_odds = current_selected_odds
        },

        clearSlip  : ( state, action) => {
         state.selected_slip.selected_odds = {}
        },

        /*
            * Expected payload 
            {
                'selected_slip_key' : 'key',
            }
        */
        setSlip: (state, action) => {
            const payload = action.payload
            let selected_slip = all_slips[payload.selected_slip_key]
            const current_selected_odds = JSON.parse(JSON.stringify({...state.selected_slip.selected_odds}))

            cacheSlip(state.current_slip_key, JSON.parse(JSON.stringify( {...selected_slip, selected_odds :current_selected_odds })))
            state.selected_slip = selected_slip

            state.current_slip_key = action.payload.selected_slip_key
        },

        /*
            * Expected payload 
            {
                amount : 10
            }
        */
        changeAmount: (state, action) => {
            state.amount = action.payload.amount
        }
    }
})

export const { addPickToSlip, setSlip, changeAmount, clearSlip, removePickFromSlip } = slipSlice.actions

export default slipSlice.reducer